<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="10"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                RENTAL INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- <v-stepper-step step="2">
                CHOOSE PLAN
              </v-stepper-step> -->

              <v-divider></v-divider>

              <v-stepper-step step="2">
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="3">
                COVERAGE
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="10"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
            @submit.prevent="next()"
          >
            <v-form
              ref="form"
              class="font-weight-bold"
              :class="mobileDevice ? 'px-2' : 'px-8'"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Rental Info
                  </div>
                </v-col>
              </v-row>
              <!------------------------Select: Details ----------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Type
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="propertyType"
                      :items="propertyTypes"
                      item-text="txt"
                      item-value="value"
                      label="Select"
                      return-object
                      :error-messages="errors"
                      outlined
                      @change="checkPropertyType"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo propertyType',
                          1,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <!----------------------- Property Address ----------------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Address
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map1"
                    ref="address1"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getPropertyAddressData"
                    @click="
                      clickedFieldGlobal(
                        'rentInfo addressAutoComp',
                        2,
                        'Renters Survey v1'
                      )
                    "
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <address-info :address="propertyAddress"></address-info>
              <!-------------------------PERSONAL DETAILS--------------------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Personal Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    :rules="{
                      required: true,
                      regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                      max: 20
                    }"
                  >
                    <v-text-field
                      v-model="currentuser.firstname"
                      :error-messages="errors"
                      label="First Name"
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'rentInfo firstname',
                          7,
                          'Renters Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Middle Initial"
                    rules="alpha"
                  >
                    <v-text-field
                      v-model="currentuser.middleinitial"
                      v-mask="'A'"
                      :error-messages="errors"
                      label="M.I."
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'rentInfo middleinitial',
                          8,
                          'Renters Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z\ -]+$/,
                      max: 30
                    }"
                  >
                    <v-text-field
                      v-model="currentuser.lastname"
                      :error-messages="errors"
                      label="Last Name"
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'rentInfo lastname',
                          9,
                          'Renters Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-dialog
                    ref="dobDialog"
                    v-model="dobDialog"
                    :return-value.sync="currentuser.dob"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Date of birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="currentuser.dob"
                          outlined
                          label="Date of Birth"
                          :error-messages="errors"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'rentInfo dob',
                              10,
                              'Renters Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="currentuser.dob"
                      scrollable
                      :min="minDate"
                      :max="todaysDate"
                      :active-picker.sync="activeBirthDatePicker"
                      color="secondary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          dobDialog = false;
                          clickedFieldGlobal(
                            'rentInfo dobCancelBtn',
                            11,
                            'Renters Survey v1'
                          );
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dobDialog.save(currentuser.dob);
                          clickedFieldGlobal(
                            'rentInfo dobOkBtn',
                            12,
                            'Renters Survey v1'
                          );
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="currentuser.email"
                      outlined
                      color="primary"
                      label="Email"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo email',
                          13,
                          'Renters Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|min:12|max:12"
                  >
                    <v-text-field
                      v-model="currentuser.phone"
                      v-mask="'###-###-####'"
                      :error-messages="errors"
                      outlined
                      label="Primary Phone Number"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo phone',
                          14,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <!--------------------------ADDITIONAL INTERESTS------------------------------------>
              <!-- <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Additional Interests
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>Do you have an Additional Interest on this property?</p>
                  <v-btn-toggle
                    v-model="additionalInterest"
                    class="mb-4"
                  >
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="true"
                      @click="
                        clickedFieldGlobal(
                          'additionalInterest - yes',
                          8,
                          'Renters Survey v1'
                        )
                      "
                    >
                      Yes
                    </v-btn>
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="false"
                      @click="
                        clickedFieldGlobal(
                          'additionalInterest - no',
                          8,
                          'Renters Survey v1'
                        )
                      "
                    >
                      No
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row v-if="additionalInterest">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nature of Interest"
                    rules="required"
                  >
                    <v-select
                      v-model="natureOfInterest"
                      outlined
                      :error-messages="errors"
                      :items="interests"
                      return-object
                      label="Nature of Interest"
                      item-value="value"
                      item-text="txt"
                      @click="
                        clickedFieldGlobal(
                          'natureOfInterest',
                          9,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col>
                  <p>
                    Do you want to add your spouse or second owner to the
                    policy?
                  </p>
                  <v-btn-toggle
                    v-model="addSecondOwner"
                    class="mb-4"
                  >
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="true"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo addSecondOwner - yes',
                          15,
                          'Renters Survey v1'
                        )
                      "
                    >
                      Yes
                    </v-btn>
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="false"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo addSecondOwner - no',
                          16,
                          'Renters Survey v1'
                        )
                      "
                    >
                      No
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <div v-if="addSecondOwner">
                <v-row>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      :rules="{
                        required: true,
                        regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                        max: 20
                      }"
                    >
                      <v-text-field
                        v-model="secondOwner.firstname"
                        :error-messages="errors"
                        label="First Name"
                        color="primary"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'rentInfo secondOwner.firstname',
                            17,
                            'Renters Survey v1'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Middle Initial"
                      rules="alpha"
                    >
                      <v-text-field
                        v-model="secondOwner.middleinitial"
                        v-mask="'A'"
                        :error-messages="errors"
                        label="M.I."
                        color="primary"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'rentInfo secondOwner.middleinitial',
                            18,
                            'Renters Survey v1'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      :rules="{
                        required: true,
                        regex: /^[a-zA-Z\ -]+$/,
                        max: 30
                      }"
                    >
                      <v-text-field
                        v-model="secondOwner.lastname"
                        :error-messages="errors"
                        label="Last Name"
                        color="primary"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'rentInfo secondOwner.lastname',
                            19,
                            'Renters Survey v1'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="4"
                  >
                    <v-dialog
                      ref="dobDialog2"
                      v-model="dobDialog2"
                      :return-value.sync="secondOwner.dob"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Date of birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="secondOwner.dob"
                            outlined
                            label="Date of Birth"
                            :error-messages="errors"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'rentInfo secondOwner.dob',
                                20,
                                'Renters Survey v1'
                              )
                            "
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="secondOwner.dob"
                        scrollable
                        :min="minDate"
                        :max="todaysDate"
                        :active-picker.sync="activeSecondBirthDatePicker"
                        color="secondary"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            dobDialog2 = false;
                            clickedFieldGlobal(
                              'rentInfo secondOwner.dobCancelBtn',
                              21,
                              'Renters Survey v1'
                            );
                          "
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dobDialog2.save(secondOwner.dob);
                            clickedFieldGlobal(
                              'rentInfo secondOwner.dobOkBtn',
                              22,
                              'Renters Survey v1'
                            );
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Existing Policy
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Existing Policy"
                    rules="required"
                  >
                    <p>
                      Do you have an existing policy with Stillwater Insurance?
                    </p>
                    <v-btn-toggle
                      v-model="existingPolicy"
                      :error-messages="errors"
                    >
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        :value="true"
                        @click="
                          clickedFieldGlobal(
                            'rentInfo existingPolicy - yes',
                            23,
                            'Renters Survey v1'
                          )
                        "
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        :value="false"
                        @click="
                          clickedFieldGlobal(
                            'rentInfo existingPolicy - no',
                            24,
                            'Renters Survey v1'
                          )
                        "
                      >
                        No
                      </v-btn>
                    </v-btn-toggle>
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="existingPolicy">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  class="pl-0"
                >
                  <p>Existing Stillwater Policy Number</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Policy Number"
                    rules="required|alpha_num|max:25"
                  >
                    <v-text-field
                      v-model="existingPolicyInfo.policyNumber"
                      outlined
                      label="Policy Number"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo existingPolicyInfo.policyNumber',
                          25,
                          'Renters Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                  <p>
                    If you have an
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        policyDialog = true;
                        clickedFieldGlobal(
                          'rentInfo policyDialog',
                          26,
                          'Renters Survey v1'
                        );
                      "
                    >existing policy with Sillwater</a>, please enter the policy number
                  </p>
                  <p>Type</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="existingPolicyInfo.LOBCd"
                      outlined
                      label="Type"
                      :items="lob"
                      return-object
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo existingPolicyInfo.LOBCd',
                          27,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                  <p>Coverage</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Coverage"
                    rules="required"
                  >
                    <v-select
                      v-model="existingPolicyInfo.policyCd"
                      outlined
                      label="Coverage"
                      :items="policyCd"
                      return-object
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo existingPolicyInfo.policyCd',
                          28,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>
              </div>
              <v-dialog
                v-model="policyDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      Your policy number can be found on the top right corner of
                      your invoice or on your policy declaration page.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      text
                      x-large
                      @click="
                        policyDialog = false;
                        clickedFieldGlobal(
                          'rentInfo policyDialogCloseBtn',
                          29,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!--------------------------LESS THAN 24 MONTHS AT ADDRESS-------------------------->
              <v-row>
                <v-col class="d-inline-flex align-center">
                  <v-checkbox
                    v-model="lessThan24"
                    @click="
                      clickedFieldGlobal(
                        'rentInfo lessThan24',
                        30,
                        'Renters Survey v1'
                      )
                    "
                  ></v-checkbox>I have lived at the above address for less than 24 months
                </v-col>
              </v-row>
              <div v-if="lessThan24">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-2">
                      Previous Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map2"
                      ref="address2"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getPreviousAddressData"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo prevAddressAutoComp',
                          31,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <address-info
                  :address="previousAddress"
                  :click-field-values="clickedFieldGlobalValue"
                  :sequence-numbers="sequenceNumbers"
                  :survey-type="surveyType"
                ></address-info>
              </div>
              <!--------------------------DIFFERENT MAILING ADDRESS-------------------------->
              <v-row>
                <v-col class="d-inline-flex align-center">
                  <v-checkbox
                    v-model="differentMailing"
                    @click="
                      clickedFieldGlobal(
                        'rentInfo differentMailing',
                        32,
                        'Renters Survey v1'
                      )
                    "
                  ></v-checkbox>I have a different Mailing Address
                </v-col>
              </v-row>
              <div v-if="differentMailing">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-2">
                      Mailing Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map3"
                      ref="address3"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getMailingAddressData"
                      @click="
                        clickedFieldGlobal(
                          'rentInfo mailingAddressAutoComp',
                          33,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <address-info :address="mailingAddress"></address-info>
              </div>
              <v-row
                v-if="nextPageLoading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="nextPageLoading"
                    :size="180"
                    img-src="src/assets/images/renters-icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="renters-icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      color="primary"
                      :disabled="invalid || nextPageLoading"
                      class="mb-2 font-weight-bold"
                      @click="
                        next();
                        clickedFieldGlobal(
                          'rentInfo next',
                          34,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-dialog
                v-model="errorDialog"
                max-width="700"
                persistent
              >
                <v-card class="pa-2">
                  <v-card-title
                    class="justify-center font-weight-bold text-h5"
                  >
                    We are unable to process your request
                  </v-card-title>
                  <v-card-text
                    class="justify-center text-center body primmary--text"
                  >
                    <v-row>
                      <v-col>
                        We’re sorry that we are unable to process your
                        application at this time due to the following error:
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="font-italic">
                        {{ error }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row
                      class="d-flex justify-center"
                      no-gutters
                    >
                      <v-col
                        class="d-flex justify-center"
                      >
                        <v-btn
                          color="primary"
                          class="mb-4 mt-4 font-weight-bold mr-0"
                          @click="clearError"
                        >
                          Modify Application
                        </v-btn>
                      </v-col>
                      <v-col
                        class="d-flex justify-center"
                      >
                        <v-btn
                          color="primary"
                          class="mb-4 mt-4 mr-0 font-weight-bold"
                          to="/"
                          @click="clearError"
                        >
                          Return to Home
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>

                  <v-row
                    no-gutters
                    class="d-flex justify-center font-weight-bold text-h5"
                  >
                    <v-col
                      class="d-flex justify-center font-weight-bold text-center"
                    >
                      Please contact us at 1-(888)-333-3145 for support with
                      your application.
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AddressInfo from '../components/Stillwater/AddressInfo.vue'
  import EllipseProgress from '../components/EllipseProgress.vue'
  import moment from 'moment'

  export default {
    components: {
      AddressInfo,
      EllipseProgress
    },
    data () {
      return {
        nextPageLoading: false,
        activeBirthDatePicker: null,
        activeSecondBirthDatePicker: null,
        existingPolicy: false,
        policyDialog: false,
        addSecondOwner: false,
        additionalInterest: false,
        natureOfInterest: '',
        dobDialog: false,
        dobDialog2: false,
        todaysDate: moment().format('YYYY-MM-DD'),
        minDate: moment()
          .subtract(75, 'years')
          .format('YYYY-MM-DD'),
        loading: false,
        propertyTypeErrorDialog: false,
        errorDialog: false,
        propertyType: '',
        propertyUse: '',
        lessThan24: false,
        differentMailing: false,
        propertyAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: ''
        },
        previousAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: ''
        },
        mailingAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: ''
        },
        currentuser: {
          firstname: '',
          middleinitial: '',
          lastname: '',
          dob: '',
          email: '',
          phone: ''
        },
        secondOwner: {
          firstname: '',
          middleinitial: '',
          lastname: '',
          dob: ''
        },
        existingPolicyInfo: {
          policyNumber: '',
          policyCd: '',
          LOBCd: ''
        },
        clickedFieldGlobalValues: [
          'rentInfo address',
          'rentInfo city',
          'rentInfo state',
          'rentInfo zip'
        ],
        sequenceNumbers: [3, 4, 5, 6],
        surveyType: 'Renters Survey v1'
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      lob () {
        return this.$store.getters.getStillwaterLOB
      },
      policyCd () {
        return this.$store.getters.getStillwaterPolicyCd
      },
      interests () {
        return this.$store.getters.getStillwaterInterestTypes
      },
      formattedPhone () {
        return '+1' + this.currentuser.phone.replace(/-/g, '')
      },
      error () {
        return this.$store.getters.error
      },
      propertyTypes () {
        return this.$store.getters.getPropertyTypes
      },
      renter () {
        let nonCA = this.propertyAddress.stateProvCd !== 'CA'

        return {
          firstname: this.currentuser.firstname,
          middleinitial: this.currentuser.middleinitial,
          lastname: this.currentuser.lastname,
          dob: this.currentuser.dob,
          email: this.currentuser.email,
          phone: this.formattedPhone,
          propertyType: this.propertyType.txt,
          propertyCode: this.propertyType.value,
          state: this.propertyAddress.stateProvCd,
          nonCA: nonCA,
          address: {
            addr1: this.propertyAddress.addr1,
            city: this.propertyAddress.city,
            stateProvCd: this.propertyAddress.stateProvCd,
            postalCode: this.propertyAddress.postalCode
          },
          mailingAddress: this.differentMailing
            ? {
              addrTypeCd: 'MailingAddress',
              addr1: this.mailingAddress.addr1,
              city: this.mailingAddress.city,
              stateProvCd: this.mailingAddress.stateProvCd,
              postalCode: this.mailingAddress.postalCode
            }
            : null,
          previousAddress: this.lessThan24
            ? {
              addrTypeCd: 'PreviousAddress',
              addr1: this.previousAddress.addr1,
              city: this.previousAddress.city,
              stateProvCd: this.previousAddress.stateProvCd,
              postalCode: this.previousAddress.postalCode
            }
            : null,
          additionalInterest: this.additionalInterest
            ? {
              generalPartyInfo: {},
              additionalInterestInfo: {
                natureInterestCd: this.natureOfInterest.value,
                interestRank: 1
              }
            }
            : null,
          secondOwner: this.addSecondOwner ? this.secondOwner : null,
          existingPolicyInfo: this.existingPolicy
            ? {
              policyNumber: this.existingPolicyInfo.policyNumber,
              policyCd: this.existingPolicyInfo.policyCd.value,
              LOBCd: this.existingPolicyInfo.LOBCd.value
            }
            : null
        }
      }
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      },
      dobDialog (val) {
        val && setTimeout(() => (this.activeBirthDatePicker = 'YEAR'))
      },
      dobDialog2 (val) {
        val && setTimeout(() => (this.activeSecondBirthDatePicker = 'YEAR'))
      },
    },
    created () {
      console.log('property types', this.propertyTypes)
      this.$store.dispatch('clearError')
      this.$store.dispatch('getPropertyTypes')
      this.$store.dispatch('getDwellUseTypes')
      this.$store.dispatch('getStillwaterLossCodes')
      this.$store.dispatch('getStillwaterInterestTypes')
      this.$store.dispatch('getStillwaterLOB')
      this.$store.dispatch('getStillwaterPolicyCd')
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      bdayvalid (value) {
        if (value.length !== 10) {
          return false
        }
        let date = moment(value)
        let valid = false
        valid = date.isValid()
        console.log('valid ', value, valid)
        return valid
      },
      checkPropertyType () {
        console.log(this.propertyType.txt)
        if (this.propertyType.txt === 'Mobile Home') {
          this.propertyTypeErrorDialog = true
        }
      },
      getPropertyAddressData (addressData, placeResultData, id) {
        console.log(addressData)
        let address = this.propertyAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      getPreviousAddressData (addressData, placeResultData, id) {
        let address = this.previousAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      getMailingAddressData (addressData, placeResultData, id) {
        console.log(addressData)
        let address = this.mailingAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      async next () {
        this.nextPageLoading = true
        let complete = await this.$refs.observer.validate()

        if (!complete) {
          console.error('Error validating form')
          return
        }

        await this.dispatchActions()
        // await this.getQuotes()

        if (this.error) {
          this.loading = false
          return
        }

        setTimeout(() => {
          this.nextPageLoading = false
          this.$router.push({ path: '/renters/propertyinfo' })
        }, 2000)
      },

      async dispatchActions () {
        try {
          const { renter } = this
          const actions = [
            'setRenter',
            'getH4RentalQuestions',
            'getH4DiscountQuestions',
            'setRentalAddress'
          ]

          for (const action of actions) {
            await this.$store.dispatch(action, renter)
          }
        } catch (error) {
          console.error('Error dispatching actions:', error)
        }
      },

      async getQuotes () {
        try {
          const { renter } = this
          const quotes = [
            {
              deductible: 500,
              type: 'large',
              amt: 25000,
              hurricaneDeductible: 1000,
              renter
            },
            {
              deductible: 1000,
              type: 'medium',
              amt: 25000,
              hurricaneDeductible: 2500,
              renter
            },
            {
              deductible: 1500,
              type: 'small',
              amt: 25000,
              hurricaneDeductible: 5000,
              renter
            }
          ]

          const results = await Promise.all(
            quotes.map(quote => this.$store.dispatch('getRentersQuote', quote))
          )

          console.log(this.$store.getters.getRentalQuotes)
        } catch (error) {
          console.error('Error getting quotes:', error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #d8fdd7;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}
</style>
