var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"light-blue lighten-5 pb-16 px-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0 d-flex justify-center align-center"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"10","md":"9","lg":"9"}},[_c('v-card',{staticClass:"white px-auto pb-0 mb-0"},[_c('v-stepper',[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":"","complete-icon":"mdi-home-circle"}},[_vm._v(" RENTAL INFO ")]),_c('v-divider'),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" PROPERTY INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" COVERAGE ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" PURCHASE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"5"}},[_vm._v(" YOUR POLICY ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"mt-0 pt-1",attrs:{"cols":"12","sm":"10","md":"9","lg":"9"}},[_c('v-card',{staticClass:"mt-0"},[_c('validation-observer',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.next()}},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',{ref:"form",staticClass:"font-weight-bold",class:_vm.mobileDevice ? 'px-2' : 'px-8'},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"title-text text-center mt-2"},[_vm._v(" Rental Info ")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Property Type ")])])],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.propertyTypes,"item-text":"txt","item-value":"value","label":"Select","return-object":"","error-messages":errors,"outlined":""},on:{"change":_vm.checkPropertyType,"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo propertyType',
                        1,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.propertyType),callback:function ($$v) {_vm.propertyType=$$v},expression:"propertyType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Property Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address1",attrs:{"id":"map1","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getPropertyAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                      'rentInfo addressAutoComp',
                      2,
                      'Renters Survey v1'
                    )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.propertyAddress}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Personal Details ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":{
                    required: true,
                    regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                    max: 20
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"First Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo firstname',
                        7,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.currentuser.firstname),callback:function ($$v) {_vm.$set(_vm.currentuser, "firstname", $$v)},expression:"currentuser.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('validation-provider',{attrs:{"name":"Middle Initial","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('A'),expression:"'A'"}],attrs:{"error-messages":errors,"label":"M.I.","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo middleinitial',
                        8,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.currentuser.middleinitial),callback:function ($$v) {_vm.$set(_vm.currentuser, "middleinitial", $$v)},expression:"currentuser.middleinitial"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":{
                    required: true,
                    regex: /^[a-zA-Z\ -]+$/,
                    max: 30
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo lastname',
                        9,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.currentuser.lastname),callback:function ($$v) {_vm.$set(_vm.currentuser, "lastname", $$v)},expression:"currentuser.lastname"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('v-dialog',{ref:"dobDialog",attrs:{"return-value":_vm.currentuser.dob,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.currentuser, "dob", $event)},"update:return-value":function($event){return _vm.$set(_vm.currentuser, "dob", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Date of Birth","error-messages":errors,"readonly":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'rentInfo dob',
                            10,
                            'Renters Survey v1'
                          )}},model:{value:(_vm.currentuser.dob),callback:function ($$v) {_vm.$set(_vm.currentuser, "dob", $$v)},expression:"currentuser.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dobDialog),callback:function ($$v) {_vm.dobDialog=$$v},expression:"dobDialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minDate,"max":_vm.todaysDate,"active-picker":_vm.activeBirthDatePicker,"color":"secondary"},on:{"update:activePicker":function($event){_vm.activeBirthDatePicker=$event},"update:active-picker":function($event){_vm.activeBirthDatePicker=$event}},model:{value:(_vm.currentuser.dob),callback:function ($$v) {_vm.$set(_vm.currentuser, "dob", $$v)},expression:"currentuser.dob"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dobDialog = false;
                        _vm.clickedFieldGlobal(
                          'rentInfo dobCancelBtn',
                          11,
                          'Renters Survey v1'
                        );}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dobDialog.save(_vm.currentuser.dob);
                        _vm.clickedFieldGlobal(
                          'rentInfo dobOkBtn',
                          12,
                          'Renters Survey v1'
                        );}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Email","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo email',
                        13,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.currentuser.email),callback:function ($$v) {_vm.$set(_vm.currentuser, "email", $$v)},expression:"currentuser.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required|min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"}],attrs:{"error-messages":errors,"outlined":"","label":"Primary Phone Number"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo phone',
                        14,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.currentuser.phone),callback:function ($$v) {_vm.$set(_vm.currentuser, "phone", $$v)},expression:"currentuser.phone"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Do you want to add your spouse or second owner to the policy? ")]),_c('v-btn-toggle',{staticClass:"mb-4",model:{value:(_vm.addSecondOwner),callback:function ($$v) {_vm.addSecondOwner=$$v},expression:"addSecondOwner"}},[_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":true},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo addSecondOwner - yes',
                        15,
                        'Renters Survey v1'
                      )}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":false},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo addSecondOwner - no',
                        16,
                        'Renters Survey v1'
                      )}}},[_vm._v(" No ")])],1)],1)],1),(_vm.addSecondOwner)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":{
                      required: true,
                      regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                      max: 20
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"First Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'rentInfo secondOwner.firstname',
                          17,
                          'Renters Survey v1'
                        )}},model:{value:(_vm.secondOwner.firstname),callback:function ($$v) {_vm.$set(_vm.secondOwner, "firstname", $$v)},expression:"secondOwner.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('validation-provider',{attrs:{"name":"Middle Initial","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('A'),expression:"'A'"}],attrs:{"error-messages":errors,"label":"M.I.","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'rentInfo secondOwner.middleinitial',
                          18,
                          'Renters Survey v1'
                        )}},model:{value:(_vm.secondOwner.middleinitial),callback:function ($$v) {_vm.$set(_vm.secondOwner, "middleinitial", $$v)},expression:"secondOwner.middleinitial"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":{
                      required: true,
                      regex: /^[a-zA-Z\ -]+$/,
                      max: 30
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'rentInfo secondOwner.lastname',
                          19,
                          'Renters Survey v1'
                        )}},model:{value:(_vm.secondOwner.lastname),callback:function ($$v) {_vm.$set(_vm.secondOwner, "lastname", $$v)},expression:"secondOwner.lastname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('v-dialog',{ref:"dobDialog2",attrs:{"return-value":_vm.secondOwner.dob,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.secondOwner, "dob", $event)},"update:return-value":function($event){return _vm.$set(_vm.secondOwner, "dob", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Date of Birth","error-messages":errors,"readonly":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                              'rentInfo secondOwner.dob',
                              20,
                              'Renters Survey v1'
                            )}},model:{value:(_vm.secondOwner.dob),callback:function ($$v) {_vm.$set(_vm.secondOwner, "dob", $$v)},expression:"secondOwner.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dobDialog2),callback:function ($$v) {_vm.dobDialog2=$$v},expression:"dobDialog2"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minDate,"max":_vm.todaysDate,"active-picker":_vm.activeSecondBirthDatePicker,"color":"secondary"},on:{"update:activePicker":function($event){_vm.activeSecondBirthDatePicker=$event},"update:active-picker":function($event){_vm.activeSecondBirthDatePicker=$event}},model:{value:(_vm.secondOwner.dob),callback:function ($$v) {_vm.$set(_vm.secondOwner, "dob", $$v)},expression:"secondOwner.dob"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dobDialog2 = false;
                          _vm.clickedFieldGlobal(
                            'rentInfo secondOwner.dobCancelBtn',
                            21,
                            'Renters Survey v1'
                          );}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dobDialog2.save(_vm.secondOwner.dob);
                          _vm.clickedFieldGlobal(
                            'rentInfo secondOwner.dobOkBtn',
                            22,
                            'Renters Survey v1'
                          );}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Existing Policy ")])])],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Existing Policy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('p',[_vm._v(" Do you have an existing policy with Stillwater Insurance? ")]),_c('v-btn-toggle',{attrs:{"error-messages":errors},model:{value:(_vm.existingPolicy),callback:function ($$v) {_vm.existingPolicy=$$v},expression:"existingPolicy"}},[_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":true},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'rentInfo existingPolicy - yes',
                          23,
                          'Renters Survey v1'
                        )}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":false},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'rentInfo existingPolicy - no',
                          24,
                          'Renters Survey v1'
                        )}}},[_vm._v(" No ")])],1)]}}],null,true)})],1)],1),(_vm.existingPolicy)?_c('div',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('p',[_vm._v("Existing Stillwater Policy Number")]),_c('validation-provider',{attrs:{"name":"Policy Number","rules":"required|alpha_num|max:25"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","label":"Policy Number","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo existingPolicyInfo.policyNumber',
                        25,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.existingPolicyInfo.policyNumber),callback:function ($$v) {_vm.$set(_vm.existingPolicyInfo, "policyNumber", $$v)},expression:"existingPolicyInfo.policyNumber"}})]}}],null,true)}),_c('p',[_vm._v(" If you have an "),_c('a',{staticClass:"primary--text text-decoration-underline",on:{"click":function($event){_vm.policyDialog = true;
                      _vm.clickedFieldGlobal(
                        'rentInfo policyDialog',
                        26,
                        'Renters Survey v1'
                      );}}},[_vm._v("existing policy with Sillwater")]),_vm._v(", please enter the policy number ")]),_c('p',[_vm._v("Type")]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","label":"Type","items":_vm.lob,"return-object":"","item-text":"txt","item-value":"value","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo existingPolicyInfo.LOBCd',
                        27,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.existingPolicyInfo.LOBCd),callback:function ($$v) {_vm.$set(_vm.existingPolicyInfo, "LOBCd", $$v)},expression:"existingPolicyInfo.LOBCd"}})]}}],null,true)}),_c('p',[_vm._v("Coverage")]),_c('validation-provider',{attrs:{"name":"Coverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","label":"Coverage","items":_vm.policyCd,"return-object":"","item-text":"txt","item-value":"value","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo existingPolicyInfo.policyCd',
                        28,
                        'Renters Survey v1'
                      )}},model:{value:(_vm.existingPolicyInfo.policyCd),callback:function ($$v) {_vm.$set(_vm.existingPolicyInfo, "policyCd", $$v)},expression:"existingPolicyInfo.policyCd"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.policyDialog),callback:function ($$v) {_vm.policyDialog=$$v},expression:"policyDialog"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_vm._v(" Your policy number can be found on the top right corner of your invoice or on your policy declaration page. ")])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","text":"","x-large":""},on:{"click":function($event){_vm.policyDialog = false;
                      _vm.clickedFieldGlobal(
                        'rentInfo policyDialogCloseBtn',
                        29,
                        'Renters Survey v1'
                      );}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-inline-flex align-center"},[_c('v-checkbox',{on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'rentInfo lessThan24',
                      30,
                      'Renters Survey v1'
                    )}},model:{value:(_vm.lessThan24),callback:function ($$v) {_vm.lessThan24=$$v},expression:"lessThan24"}}),_vm._v("I have lived at the above address for less than 24 months ")],1)],1),(_vm.lessThan24)?_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Previous Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address2",attrs:{"id":"map2","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getPreviousAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo prevAddressAutoComp',
                        31,
                        'Renters Survey v1'
                      )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.previousAddress,"click-field-values":_vm.clickedFieldGlobalValue,"sequence-numbers":_vm.sequenceNumbers,"survey-type":_vm.surveyType}})],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-inline-flex align-center"},[_c('v-checkbox',{on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'rentInfo differentMailing',
                      32,
                      'Renters Survey v1'
                    )}},model:{value:(_vm.differentMailing),callback:function ($$v) {_vm.differentMailing=$$v},expression:"differentMailing"}}),_vm._v("I have a different Mailing Address ")],1)],1),(_vm.differentMailing)?_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Mailing Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address3",attrs:{"id":"map3","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getMailingAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                        'rentInfo mailingAddressAutoComp',
                        33,
                        'Renters Survey v1'
                      )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.mailingAddress}})],1):_vm._e(),(_vm.nextPageLoading)?_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('ellipse-progress',{attrs:{"loading":_vm.nextPageLoading,"size":180,"img-src":"src/assets/images/renters-icon.png","thickness":"5","empty-thickness":"3","line-mode":"out 5","legend":"false","animation":"rs 700 1000","font-size":"1.5rem","color":"#00A1B7","empty-color-fill":"transparent","filename":"renters-icon.png"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mb-2 font-weight-bold",attrs:{"x-large":"","color":"primary","disabled":invalid || _vm.nextPageLoading},on:{"click":function($event){_vm.next();
                      _vm.clickedFieldGlobal(
                        'rentInfo next',
                        34,
                        'Renters Survey v1'
                      );}}},[_vm._v(" Next ")])],1)])],1),_c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',{staticClass:"justify-center font-weight-bold text-h5"},[_vm._v(" We are unable to process your request ")]),_c('v-card-text',{staticClass:"justify-center text-center body primmary--text"},[_c('v-row',[_c('v-col',[_vm._v(" We’re sorry that we are unable to process your application at this time due to the following error: ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mb-4 mt-4 font-weight-bold mr-0",attrs:{"color":"primary"},on:{"click":_vm.clearError}},[_vm._v(" Modify Application ")])],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mb-4 mt-4 mr-0 font-weight-bold",attrs:{"color":"primary","to":"/"},on:{"click":_vm.clearError}},[_vm._v(" Return to Home ")])],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center font-weight-bold text-h5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center font-weight-bold text-center"},[_vm._v(" Please contact us at 1-(888)-333-3145 for support with your application. ")])],1)],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }