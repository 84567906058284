<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Address"
          rules="required"
        >
          <v-text-field
            v-model="address.addr1"
            label="Address"
            outlined
            :error-messages="errors"
            @click="
              clickedFieldGlobal(
                clickFieldValues[0],
                sequenceNumbers[0],
                surveyType
              )
            "
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <validation-provider
          v-slot="{ errors }"
          name="City"
          rules="required"
        >
          <v-text-field
            v-model="address.city"
            label="City"
            outlined
            :error-messages="errors"
            @click="
              clickedFieldGlobal(
                clickFieldValues[1],
                sequenceNumbers[1],
                surveyType
              )
            "
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <validation-provider
          v-slot="{ errors }"
          name="State"
          rules="required"
        >
          <v-select
            v-model="address.stateProvCd"
            label="State"
            :items="states"
            outlined
            :error-messages="errors"
            @click="
              clickedFieldGlobal(
                clickFieldValues[2],
                sequenceNumbers[2],
                surveyType
              )
            "
          ></v-select>
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Zip"
          :rules="{ required: true, regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/ }"
        >
          <v-text-field
            v-model="address.postalCode"
            label="Zip"
            outlined
            :error-messages="errors"
            @click="
              clickedFieldGlobal(
                clickFieldValues[3],
                sequenceNumbers[3],
                surveyType
              )
            "
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { STATES } from '@/consts/us-states'
  export default {
    name: 'AddressInfo',
    props: {
      address: {
        type: Object,
        default: () => ({
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: ''
        })
      },
      clickFieldValues: { type: Array, default: () => [] },
      sequenceNumbers: { type: Array, default: () => [] },
      surveyType: { type: String, default: '' }
    },
    data () {
      return {
        states: STATES
      }
    }
  }
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
</style>
